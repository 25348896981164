import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyBK-qRVNYfkV9cusQnIxRJ3nV7p9aId5Mo",
    authDomain: "bot-tr-eniyikasinobot.firebaseapp.com",
    projectId: "bot-tr-eniyikasinobot",
    storageBucket: "bot-tr-eniyikasinobot.firebasestorage.app",
    messagingSenderId: "475058035991",
    appId: "1:475058035991:web:fb2f21b9673a15ea192b52"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };