import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../firebase";
import "./SendMessage.css"

const SendMessage = () => {
    const [textMessage, setTextMessage] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [userIds, setUserIds] = useState([]);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttons, setButtons] = useState([{ text: '', url: '' }]);
    const [sendWithVideo, setSendWithVideo] = useState(true); // New state for checkbox
    const videoRef = useRef(null);

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const BOT_TOKEN = "7422267885:AAHOayVq7khLFgvDrBOfLCurfsql2VtqTUg";

    const fetchUserIds = useCallback(async () => {
        try {
            const usersCollection = collection(db, 'users');
            const userSnapshot = await getDocs(usersCollection);
            const ids = userSnapshot.docs.map(doc => doc.data().userId);
            setUserIds(ids);
        } catch (error) {
            console.error('Error fetching user IDs:', error);
            setStatus('Failed to fetch user IDs. Please try again.');
        }
    }, [db]);

    useEffect(() => {
        fetchUserIds();
    }, [fetchUserIds]);

    const handleTextMessageChange = (e) => setTextMessage(e.target.value);

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
            const newVideoUrl = URL.createObjectURL(file);
            setVideoPreview(newVideoUrl);
        }
    };

    const handleSendWithVideoChange = (e) => {
        setSendWithVideo(e.target.checked);
    };

    const handleButtonChange = (index, field, value) => {
        const newButtons = [...buttons];
        newButtons[index] = { ...newButtons[index], [field]: value };
        setButtons(newButtons);
    };

    const addButton = () => {
        setButtons([...buttons, { text: '', url: '' }]);
    };

    const removeButton = (index) => {
        setButtons(buttons.filter((_, i) => i !== index));
    };

    useEffect(() => {
        return () => {
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
        };
    }, [videoPreview]);

    const createInlineKeyboard = () => {
        const validButtons = buttons.filter(btn => btn.text && btn.url);
        if (validButtons.length === 0) return null;

        return {
            inline_keyboard: [
                validButtons.map(btn => ({
                    text: btn.text,
                    url: btn.url
                }))
            ]
        };
    };

    const sendMessageAndVideoToTelegram = async () => {
        if (!videoFile && !textMessage) {
            setStatus('Please select a video file or enter a text message.');
            return;
        }

        setLoading(true);
        setStatus('Sending message and/or video to all users...');
        let successCount = 0;
        let failCount = 0;

        const inlineKeyboard = createInlineKeyboard();

        try {
            for (const userId of userIds) {
                try {
                    if (videoFile) {
                        // Send video with caption if checkbox is checked
                        if (sendWithVideo && textMessage) {
                            const formData = new FormData();
                            formData.append('chat_id', userId);
                            formData.append('caption', textMessage);
                            formData.append('video', videoFile);
                            if (inlineKeyboard) {
                                formData.append('reply_markup', JSON.stringify(inlineKeyboard));
                            }

                            const response = await axios.post(
                                `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                formData,
                                {
                                    headers: { 'Content-Type': 'multipart/form-data' }
                                }
                            );

                            if (response.data && response.data.ok) {
                                successCount++;
                            } else {
                                failCount++;
                            }
                        } else {
                            // Send video without caption
                            const videoFormData = new FormData();
                            videoFormData.append('chat_id', userId);
                            videoFormData.append('video', videoFile);
                            if (inlineKeyboard) {
                                videoFormData.append('reply_markup', JSON.stringify(inlineKeyboard));
                            }

                            const videoResponse = await axios.post(
                                `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                videoFormData,
                                {
                                    headers: { 'Content-Type': 'multipart/form-data' }
                                }
                            );

                            // Send text as separate message if checkbox is unchecked
                            if (!sendWithVideo && textMessage) {
                                const messageData = {
                                    chat_id: userId,
                                    text: textMessage,
                                    reply_markup: inlineKeyboard
                                };

                                await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                                    messageData
                                );
                            }

                            if (videoResponse.data && videoResponse.data.ok) {
                                successCount++;
                            } else {
                                failCount++;
                            }
                        }
                    } else if (textMessage) {
                        // Send text-only message
                        const messageData = {
                            chat_id: userId,
                            text: textMessage
                        };

                        if (inlineKeyboard) {
                            messageData.reply_markup = inlineKeyboard;
                        }

                        const response = await axios.post(
                            `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                            messageData
                        );

                        if (response.data && response.data.ok) {
                            successCount++;
                        } else {
                            failCount++;
                        }
                    }
                } catch (error) {
                    failCount++;
                    console.error(`Error sending to user ${userId}:`, error.response ? error.response.data : error.message);
                }
            }

            // Reset form after successful sending
            setTextMessage('');
            setVideoFile(null);
            setVideoPreview(null);
            setButtons([{ text: '', url: '' }]);

        } catch (error) {
            console.error('Error in send operation:', error);
            setStatus('An error occurred while sending messages. Please try again.');
        } finally {
            setLoading(false);
            setStatus(`Message/Video sent to ${successCount} users. Failed for ${failCount} users.`);
        }
    };

    return (
        <div className="send-message">
            <h2>Send Message and Video to Telegram</h2>

            <div className="message-form">
                <div className="text-input-container">
                    <textarea
                        value={textMessage}
                        onChange={handleTextMessageChange}
                        placeholder="Enter your message"
                        className="message-textarea"
                        disabled={loading}
                    />
                </div>

                <div className="file-input-container">
                    <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoChange}
                        disabled={loading}
                        className="file-input"
                    />
                    {videoFile && (
                        <div className="video-options">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={sendWithVideo}
                                    onChange={handleSendWithVideoChange}
                                    disabled={loading}
                                />
                                Send text as video caption
                            </label>
                        </div>
                    )}
                </div>

                <div className="buttons-section">
                    <h3>Inline URL Buttons</h3>
                    {buttons.map((button, index) => (
                        <div key={index} className="button-inputs">
                            <input
                                type="text"
                                value={button.text}
                                onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                                placeholder="Button Text"
                                className="button-input"
                                disabled={loading}
                            />
                            <input
                                type="url"
                                value={button.url}
                                onChange={(e) => handleButtonChange(index, 'url', e.target.value)}
                                placeholder="Button URL (https://...)"
                                className="button-input"
                                disabled={loading}
                            />
                            <button
                                onClick={() => removeButton(index)}
                                className="remove-button"
                                disabled={loading || buttons.length === 1}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        onClick={addButton}
                        className="add-button"
                        disabled={loading}
                    >
                        Add Button
                    </button>
                </div>

                {videoPreview && (
                    <div className="video-preview">
                        <video
                            ref={videoRef}
                            controls
                            src={videoPreview}
                            width="300"
                        />
                    </div>
                )}

                <button
                    onClick={sendMessageAndVideoToTelegram}
                    disabled={loading || (!videoFile && !textMessage)}
                    className={`send-button ${loading ? 'loading' : ''}`}
                >
                    {loading ? 'Sending...' : 'Send'}
                </button>

                {status && (
                    <div className={`status-message ${status.includes('Failed') ? 'error' : 'success'}`}>
                        {status}
                    </div>
                )}
            </div>

            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default SendMessage;