import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import * as XLSX from 'xlsx';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../firebase";
import './Dashboard.css';

// Create context for shared data
export const DashboardContext = createContext();

const Dashboard = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [messageStatus, setMessageStatus] = useState('');
    const [cachedUsers, setCachedUsers] = useState(null);
    const [lastFetch, setLastFetch] = useState(null);

    const navigate = useNavigate();
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const handleLogout = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const fetchAllUsers = async (force = false) => {
        // Check if we have cached data and it's less than 5 minutes old
        const now = Date.now();
        if (!force && cachedUsers && lastFetch && (now - lastFetch) < 300000) {
            setMessageStatus(`Displaying ${cachedUsers.length} cached user names`);
            return cachedUsers;
        }

        try {
            const usersCollection = collection(db, 'users');
            const userSnapshot = await getDocs(usersCollection);
            const users = userSnapshot.docs.map(doc => doc.data());

            // Update cache and last fetch time
            setCachedUsers(users);
            setLastFetch(now);

            setMessageStatus(`Fetched ${users.length} user names`);
            return users;
        } catch (error) {
            console.error('Error fetching user names:', error);
            setMessageStatus('Failed to fetch user names. Please try again.');
            return null;
        }
    };

    const exportUsersToExcel = async () => {
        try {
            // Use cached users if available, otherwise fetch
            const users = cachedUsers || await fetchAllUsers();
            if (!users) return;

            const worksheet = XLSX.utils.json_to_sheet(users);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
            XLSX.writeFile(workbook, "users.xlsx");
            setMessageStatus('Users exported to Excel successfully');
        } catch (error) {
            console.error('Error exporting users to Excel:', error);
            setMessageStatus('Failed to export users to Excel. Please try again.');
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Context value for child components
    const contextValue = {
        messageStatus,
        setMessageStatus,
        cachedUsers,
        fetchAllUsers,
        lastFetch
    };

    return (
        <DashboardContext.Provider value={contextValue}>
            <div className="dashboard">
                <div className={`burger-menu ${isMenuOpen ? 'open' : ''}`}>
                    <button onClick={toggleMenu}>☰</button>
                    {isMenuOpen && (
                        <div className="dropdown-menu">
                            <button onClick={() => fetchAllUsers(true)}>Fetch All Users</button>
                            <button onClick={exportUsersToExcel}>Export Users to Excel</button>
                            <button onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
                <div className="sidebar">
                    <Link to="sendmessage" className="nav-link">
                        Send Message and Video to Telegram
                    </Link>
                    <Link to="updatebot" className="nav-link">
                        Update Bot Content
                    </Link>
                </div>
                <div className="content">
                    <Outlet />
                    <p className="status-message">{messageStatus}</p>
                </div>
            </div>
        </DashboardContext.Provider>
    );
};

export default Dashboard;